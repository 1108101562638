<mat-toolbar class="matpopup-header">
  <div>Paste Attributes</div>
</mat-toolbar>
<div class="p-5">
  <div fxLayout="row" fxShow="false" fxShow.gt-xs="true" fxLayoutAlign="center center" fxLayoutGap="10px"
    class="search-bar">
    <mat-select [(ngModel)]="selected_entity_type" (selectionChange)="getEntity($event)" class="form-select-l"
      placeholder="Entity">
      <mat-option *ngFor="let ent of api.entity_type_list" [value]="ent.entity_name">{{ent.entity_name}}</mat-option>
    </mat-select>
    <mat-select *ngIf="is_property_dependent" [(ngModel)]="selected_property_types" multiple class="form-select-l"
      placeholder="Property Type">
      <mat-option *ngFor="let prp of api.property_type_list;"
        [value]="prp.property_type_id">{{prp.property_type_label[api.language]}}</mat-option>
    </mat-select>
  </div>
</div>
<div class="center">
  <button mat-button class="bluebutton w-100 h-40" (click)="pasteAttributes()"
    [disabled]="!selected_entity_type || (is_property_dependent == true && selected_entity_type == 'property_unit' && selected_property_types.length == 0)"
    [ngClass]="{'dis': !selected_entity_type || (is_property_dependent == true && selected_entity_type == 'property_unit' && selected_property_types.length == 0)}">
    Paste
  </button>
  <button mat-button class="orangebutton w-100 h-40" (click)="close()">
    Cancel
  </button>
</div>
