import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

@Component({
  selector: "attribute-dialog",
  templateUrl: "./attribute-dialog.html",
  styleUrls: ["./copy-attribute-dialog.component.scss"],
})
export class AttributeDialog {
  selected_attr_list: any;
  selected_entity_type: any;
  selected_property_types: any = [];
  is_property_dependent: boolean;
  constructor(
    public dialogRef: MatDialogRef<AttributeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public api: ApiserviceService,
    private fnc: CommonfunctionService
  ) {
    this.selected_attr_list = this.data.parent.selected_attr_list;
  }

  ngOninit() {}

  getEntity(e) {
    if (e.value == "property_unit") {
      this.is_property_dependent = true;
    } else {
      this.is_property_dependent = false;
    }
  }
  pasteAttributes() {
    let data = {
      added_by: this.api.user_id,
      attribute_ids: this.selected_attr_list,
    };
    if (this.selected_property_types.length == 0) {
      data["property_type_ids"] = null;
    } else {
      data["property_type_ids"] = this.selected_property_types;
    }

    this.api
      .postEmsData(this.selected_entity_type + "/attributes/copy", data)
      .subscribe({
        next: (res) => {
          this.data.parent.notify.notify(
            "Attribute copied successfully!",
            "success"
          );
          this.close();
        },
        error: (err) => {
          this.data.parent.notify.notify(
            this.fnc.getErrorMessage(err),
            "error"
          );
        },
      });
  }
  close() {
    this.dialogRef.close();
  }
}
