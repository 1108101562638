<div class="table-wrapper">
  <table mat-table [dataSource]="data" class="">
    <!-- Dynamic Columns -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef class="sticky-header">
        {{ column | removeUnderscore }}
      </th>

      <td mat-cell *matCellDef="let element; let i = index">
        <ng-container *ngIf="column === 's_no'">
          <div> {{i + 1 + offset}} </div>
        </ng-container>
        <!-- Conditionally render buttons based on the column name -->
        <ng-container *ngIf="column === 'log'">
          <div class="button-wrapper">
            <ng-container *ngFor="let button of buttons.log">
              <button
              *ngIf="button.label == 'Validate Log'
              || button.label == 'Error Log' && element.status == -1
              || button.label == 'Accept Error' && element.status == -2
              || button.label == 'Accept Log' && element.status == 2"
              mat-button
              (click)="button.action(element)"
              [ngStyle]="button.style"
              [ngClass]="button.class"
              [disabled]="isButtonDisabled(button)"
            >
              {{ button.label }}
            </button>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="column === 'action'">
          <div class="button-wrapper">
        <ng-container
           *ngFor="let button of buttons?.action">
          <button
          class="action-btn"
          (click)="button?.action(element)"
          [ngStyle]="button?.style"
          [disabled]="element.status !== 1"
        >
          {{ button?.label }}
        </button>
        </ng-container>

          </div>
        </ng-container>

        <!-- Default case: show the data -->
        <ng-container
          *ngIf="column !== 'log' && column !== 'action' && column !== 'status' && column !== 's_no'"
        >
          <div
            matTooltip="{{ formatValue(element[column]) }}"
            matTooltipDisabled="{{ !isTooltipNeeded(element[column]) }}"
          >
            {{ formatDisplayValue(element[column]) }}
          </div>
        </ng-container>
        <ng-container *ngIf="column === 'status'">
          <div
            class="status"
            [ngStyle]="{ color: statusMap[element[column]]?.color || 'black' }"
            matTooltip="{{
              statusMap[element[column]]?.label || 'Unknown Status'
            }}"
          >
            {{ statusMap[element[column]]?.label || "Unknown Status" }}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
<div class="pagination-wrapper">
  <mat-paginator
    [length]="totalCount"
    showFirstLastButtons
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageAction($event)"
  >
  </mat-paginator>
</div>
