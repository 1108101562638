<div class="parent-container">
  <mat-card appearance="outlined" class="example-card">
      <div class="or-section p-2">
          <div class="grid-ap">
              <div class="mat-h2 title">New CSV Upload </div>
              <div class="cent">
                  <!-- <button mat-button matTooltip="Info" (click)="openDialog()" class="refresh1">
                      <mat-icon>info</mat-icon>
                  </button> -->
                  <small class="margl5">
                      <button [matMenuTriggerFor]="menu" mat-raised-button color="primary"> {{download}} <mat-icon>
                          keyboard_arrow_down
                          </mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="createCsvSample(0)">Empty template</button>
                          <button mat-menu-item (click)="createCsvSample(1)">Selected
                          unit type with data</button>
                          <button mat-menu-item (click)="createCsvSample(2)">All
                          property with data</button>
                          <button mat-menu-item (click)="createCsvSample(2,'2,7')">All
                          property with data(Review)</button>
                      </mat-menu>
                  </small>
              </div>
          </div>
      </div>
      <mat-card-content>

          <div class="gis_data_form">
              <div>
                  <div class="panel_div">


                      <div class="ml-10  d-flex align-items-center">
                          <label for="">Assignment <span class="mandatory">*</span> : </label>
                          <mat-select placeholder="Select Assignment" (openedChange)="searchAssignment=''" [(ngModel)]="selected_assignment" class="form-select-l">
                              <div class="search_bar"><input [(ngModel)]="searchAssignment" placeholder="Type here to search..." (keydown)="$event.stopPropagation()" /></div>
                              <mat-option class="cp" *ngFor="let data of assignmentList | searchfilter:'name':searchAssignment:assignmentList" [value]="data.assignment_id">
                                  {{getName(data.assignment_label) == undefined ? data.assignment_name : getName(data.assignment_label)}}_{{data?.year}}
                              </mat-option>
                          </mat-select>
                      </div>



                      <div class="ml-10  d-flex align-items-center">
                          <label for="">Unit Type <span class="mandatory">*</span> : </label>
                          <mat-select multiple placeholder="Select Property Type" (openedChange)="searchType=''" [(ngModel)]="selected_property_type" class="form-select-l">
                              <div class="search_bar"><input [(ngModel)]="searchType" placeholder="Type here to search name..." (keydown)="$event.stopPropagation()" /></div>
                              <mat-option class="cp" *ngFor="let prp of property_type_list | searchfilter:'property_type_name':searchType" [value]="prp.property_type_id">
                                <span [title]="prp.property_type_label[api.language]"> {{prp.property_type_label[api.language].length > 64 ? (prp.property_type_label[api.language] | slice:0:64)+'...' : prp.property_type_label[api.language]}}</span>
                                <span *ngIf="prp.property_type_label[api.language] == undefined " [title]="prp.property_type_name">{{prp.property_type_name}}</span>
                              </mat-option>
                          </mat-select>
                      </div>

                  </div>
              </div>

              <div class="gridV">
                  <div>
                      <!-- <span class="fntcustom" *ngIf="fileName">{{fileName}}</span> -->
                      <mat-chip-option class="vspace" *ngIf="fileName" [selectable]="true" [removable]="true" (removed)="reset()">
                          {{fileName}}
                          <mat-icon matChipRemove class="lnk Rejected">cancel</mat-icon>
                      </mat-chip-option>

                  </div>
                  <div class="btnGrid">
                      <div class="upload-btn-wrapper">
                          <div class="wrapper">
                              <button class="sel">Select file</button>
                              <p>Or Drag & drop file here</p>
                          </div>
                          <input type="file" #myFileInput name="myfile" (change)="handleFileInput($event)" accept=".zip" />
                      </div>
                      <button class="submit" type="submit" [disabled]="loading" mat-raised-button color="primary" (click)="uploadFile()">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
                          </svg>
                          <span *ngIf="!loading">Upload</span>
                          <span *ngIf="loading">Uploading...</span>
                      </button>
                  </div>
              </div>

          </div>
      </mat-card-content>
      <div class="bbar w-100" *ngIf="loading">
          <mat-progress-bar mode="indeterminate" class="btm"></mat-progress-bar>
      </div>

  </mat-card>
  <mat-card appearance="outlined" class="example-card">
      <div class="or-section p-2">
          <div class="grid-ap">
              <div class="mat-h2 title">
                  Upload Status<span><small *ngIf="selected_File_Type" class="cp">&nbsp;- {{selected_File_Type}}</small></span>
              </div>
              <div class="text-right mr-1">
                  <button mat-icon-button  matTooltip="Refresh list" class="refresh" (click)="getUploadStatus()">
                      <mat-icon>refresh</mat-icon>
                  </button>
              </div>
          </div>
      </div>
      <mat-card-content class="tabs">
          <div class="table-estater table" style="position: relative">
                  <table class="attr-table">
                      <thead>
                          <tr>
                              <!-- <th> S. No.</th> -->
                              <th> Upload Id</th>

                              <th> Initial Count
                                  <span matTooltip="Property | Floor | Unit"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="15" height="15" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
                                      <g xmlns="http://www.w3.org/2000/svg">
                                          <g>
                                              <g>
                                                  <path d="M290.211,360.727c-5.234,0.488-10.489-0.693-15.011-3.375c-3.304-3.392-4.888-8.101-4.305-12.8     c0.122-3.912,0.589-7.806,1.396-11.636c0.781-4.394,1.79-8.744,3.025-13.033l13.731-47.244c1.416-4.663,2.352-9.459,2.793-14.313     c0-5.236,0.698-8.844,0.698-10.938c0.292-9.333-3.693-18.289-10.822-24.32c-8.769-6.732-19.689-10.041-30.72-9.309     c-7.905,0.119-15.749,1.413-23.273,3.84c-8.223,2.56-16.873,5.624-25.949,9.193l-3.956,15.36     c2.676-0.931,5.935-1.978,9.658-3.142c3.552-1.052,7.234-1.601,10.938-1.629c5.196-0.563,10.426,0.713,14.778,3.607     c2.956,3.527,4.343,8.109,3.84,12.684c-0.013,3.913-0.442,7.814-1.28,11.636c-0.815,4.073-1.862,8.378-3.142,12.916     l-13.847,47.476c-1.116,4.413-2.009,8.879-2.676,13.382c-0.544,3.855-0.816,7.743-0.815,11.636     c-0.057,9.397,4.24,18.291,11.636,24.087c8.904,6.837,19.98,10.226,31.185,9.542c7.89,0.162,15.753-0.978,23.273-3.375     c6.594-2.25,15.399-5.469,26.415-9.658l3.724-14.662c-2.984,1.238-6.057,2.249-9.193,3.025     C298.346,360.583,294.274,360.935,290.211,360.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                  <path d="M304.756,136.727c-6.333-5.816-14.677-8.945-23.273-8.727c-8.591-0.194-16.927,2.932-23.273,8.727     c-11.632,10.03-12.931,27.591-2.9,39.224c0.894,1.037,1.863,2.006,2.9,2.9c13.252,11.853,33.294,11.853,46.545,0     c11.632-10.129,12.851-27.769,2.722-39.401C306.635,138.481,305.725,137.571,304.756,136.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                  <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,488.727     C127.468,488.727,23.273,384.532,23.273,256S127.468,23.273,256,23.273S488.727,127.468,488.727,256S384.532,488.727,256,488.727     z" fill="#ffffff" data-original="#000000" class=""></path>
                                              </g>
                                          </g>
                                      </g>
                                      </g>
                                  </svg></span>
                              </th>
                              <th> Validate Count
                                  <span matTooltip="Property (Failed count) | Floor (Failed count) | Unit (Failed count)"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="15" height="15" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
                                      <g xmlns="http://www.w3.org/2000/svg">
                                          <g>
                                              <g>
                                                  <path d="M290.211,360.727c-5.234,0.488-10.489-0.693-15.011-3.375c-3.304-3.392-4.888-8.101-4.305-12.8     c0.122-3.912,0.589-7.806,1.396-11.636c0.781-4.394,1.79-8.744,3.025-13.033l13.731-47.244c1.416-4.663,2.352-9.459,2.793-14.313     c0-5.236,0.698-8.844,0.698-10.938c0.292-9.333-3.693-18.289-10.822-24.32c-8.769-6.732-19.689-10.041-30.72-9.309     c-7.905,0.119-15.749,1.413-23.273,3.84c-8.223,2.56-16.873,5.624-25.949,9.193l-3.956,15.36     c2.676-0.931,5.935-1.978,9.658-3.142c3.552-1.052,7.234-1.601,10.938-1.629c5.196-0.563,10.426,0.713,14.778,3.607     c2.956,3.527,4.343,8.109,3.84,12.684c-0.013,3.913-0.442,7.814-1.28,11.636c-0.815,4.073-1.862,8.378-3.142,12.916     l-13.847,47.476c-1.116,4.413-2.009,8.879-2.676,13.382c-0.544,3.855-0.816,7.743-0.815,11.636     c-0.057,9.397,4.24,18.291,11.636,24.087c8.904,6.837,19.98,10.226,31.185,9.542c7.89,0.162,15.753-0.978,23.273-3.375     c6.594-2.25,15.399-5.469,26.415-9.658l3.724-14.662c-2.984,1.238-6.057,2.249-9.193,3.025     C298.346,360.583,294.274,360.935,290.211,360.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                  <path d="M304.756,136.727c-6.333-5.816-14.677-8.945-23.273-8.727c-8.591-0.194-16.927,2.932-23.273,8.727     c-11.632,10.03-12.931,27.591-2.9,39.224c0.894,1.037,1.863,2.006,2.9,2.9c13.252,11.853,33.294,11.853,46.545,0     c11.632-10.129,12.851-27.769,2.722-39.401C306.635,138.481,305.725,137.571,304.756,136.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                  <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,488.727     C127.468,488.727,23.273,384.532,23.273,256S127.468,23.273,256,23.273S488.727,127.468,488.727,256S384.532,488.727,256,488.727     z" fill="#ffffff" data-original="#000000" class=""></path>
                                              </g>
                                          </g>
                                      </g>
                                      </g>
                                  </svg></span>
                                  <!-- <div class="chunkTool" *ngIf="showChunk">
                                      <span (click)="showChunk = false"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16.004"><defs><style>.a{fill:#777;}</style></defs><path class="a" d="M.227,1.335a.8.8,0,0,1,0-1.11.8.8,0,0,1,1.11,0L8,6.9,14.67.225a.781.781,0,0,1,1.1,1.11L9.108,8l6.661,6.672a.781.781,0,0,1-1.1,1.11L8,9.107,1.337,15.779a.8.8,0,0,1-1.11,0,.8.8,0,0,1,0-1.11L6.888,8Z" transform="translate(-0.002 0)"/></svg></span>
                                      <p>Property / Floor / Unit</p>
                                  </div> -->
                              </th>
                              <th> Accept Count
                                  <span matTooltip="Accept Count | Failed Count"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="15" height="15" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
                                      <g xmlns="http://www.w3.org/2000/svg">
                                          <g>
                                              <g>
                                                  <path d="M290.211,360.727c-5.234,0.488-10.489-0.693-15.011-3.375c-3.304-3.392-4.888-8.101-4.305-12.8     c0.122-3.912,0.589-7.806,1.396-11.636c0.781-4.394,1.79-8.744,3.025-13.033l13.731-47.244c1.416-4.663,2.352-9.459,2.793-14.313     c0-5.236,0.698-8.844,0.698-10.938c0.292-9.333-3.693-18.289-10.822-24.32c-8.769-6.732-19.689-10.041-30.72-9.309     c-7.905,0.119-15.749,1.413-23.273,3.84c-8.223,2.56-16.873,5.624-25.949,9.193l-3.956,15.36     c2.676-0.931,5.935-1.978,9.658-3.142c3.552-1.052,7.234-1.601,10.938-1.629c5.196-0.563,10.426,0.713,14.778,3.607     c2.956,3.527,4.343,8.109,3.84,12.684c-0.013,3.913-0.442,7.814-1.28,11.636c-0.815,4.073-1.862,8.378-3.142,12.916     l-13.847,47.476c-1.116,4.413-2.009,8.879-2.676,13.382c-0.544,3.855-0.816,7.743-0.815,11.636     c-0.057,9.397,4.24,18.291,11.636,24.087c8.904,6.837,19.98,10.226,31.185,9.542c7.89,0.162,15.753-0.978,23.273-3.375     c6.594-2.25,15.399-5.469,26.415-9.658l3.724-14.662c-2.984,1.238-6.057,2.249-9.193,3.025     C298.346,360.583,294.274,360.935,290.211,360.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                  <path d="M304.756,136.727c-6.333-5.816-14.677-8.945-23.273-8.727c-8.591-0.194-16.927,2.932-23.273,8.727     c-11.632,10.03-12.931,27.591-2.9,39.224c0.894,1.037,1.863,2.006,2.9,2.9c13.252,11.853,33.294,11.853,46.545,0     c11.632-10.129,12.851-27.769,2.722-39.401C306.635,138.481,305.725,137.571,304.756,136.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                  <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,488.727     C127.468,488.727,23.273,384.532,23.273,256S127.468,23.273,256,23.273S488.727,127.468,488.727,256S384.532,488.727,256,488.727     z" fill="#ffffff" data-original="#000000" class=""></path>
                                              </g>
                                          </g>
                                      </g>
                                      </g>
                                  </svg></span>
                              </th>
                              <th>File Name</th>
                              <th> Validate Time</th>
                              <th> Accept Time</th>
                              <th> Assignment</th>
                              <th> Added Date</th>
                              <th> Status</th>
                              <th> View Log</th>
                              <th> Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let data of upload_report; let k = index">
                              <!-- <td width="5%">{{offset+k+1}}</td> -->
                              <td width="5%"> {{data.upload_id}}</td>

                              <td> {{data.initial_property_count}} / {{data.initial_floor_count}} / {{data.initial_unit_count}}</td>
                              <td>
                                  <p>{{data.validate_property_count}}
                                  <span class="text-danger"  title="Failed property count" *ngIf="data.f_count_show">({{data.failed_validate_property_count}})</span>
                                  / {{data.validate_floor_count}}
                                  <span class="text-danger"  title="Failed floor count"  *ngIf="data.f_count_show">({{data.failed_validate_floor_count}})</span>
                                  / {{data.validate_unit_count}}
                                  <span class="text-danger"  title="Failed unit count"  *ngIf="data.f_count_show">({{data.failed_validate_unit_count}})</span>
                                  </p>
                              </td>
                              <td>
                                  <span>{{data.accept_count_property}}</span>
                                  <span title="Failed accept property count" *ngIf="data.failed_accept_property_count != '0' || data.failed_accept_floor_count != 0 || data.faile_accept_unit_count != 0">  <span class="text-danger">
                                    ({{data.failed_accept_property_count}})</span>
                                  </span>
                                 <span class="floor-count"> / {{data.accept_count_floor}}  <span class="failed-count text-danger" *ngIf="data.failed_accept_property_count != '0' || data.failed_accept_floor_count != 0 || data.faile_accept_unit_count != 0">({{data.failed_accept_floor_count}})</span> </span>
                                 <span class="unit-count"> / {{data.accept_count_unit}}  <span class="failed-count text-danger" *ngIf="data.failed_accept_property_count != '0' || data.failed_accept_floor_count != 0 || data.faile_accept_unit_count != 0">({{data.faile_accept_unit_count}})</span></span>
                              </td>
                              <td> <span class="file-name" title="{{data.name}}">{{data.name}}</span></td>
                              <td> {{data.validate_time}} </td>
                              <td> {{data.accept_time}} </td>
                              <td wisth="20%" style="text-transform: capitalize;">{{data?.assignment_name}}_{{data?.assignment_year}}</td>
                              <td>{{data.added_date}}</td>
                              <td [ngClass]="getFileStatus(data)"><span matTooltip="{{(data.remarks)? data.remarks :''}}">
                                <span *ngIf="data.failed_accept_property_count != '0' || data.failed_accept_floor_count != 0 || data.faile_accept_unit_count != 0">Partially accepted</span>
                                <span *ngIf="data.failed_accept_property_count == '0' && data.failed_accept_floor_count == 0 && data.faile_accept_unit_count == 0">{{getFileStatus(data)}}</span>
                              </span> </td>
                              <td class="text-center">
                                <button class="dl-btn download" (click)="downloadLogs(data)">Logs</button>
                              </td>
                              <td>
                                  <button class="dl-btn accept" (click)="acceptUploadNewFile(data)"  [disabled]="hideAccept(data.status)">Accept</button>
                                  <button class="dl-btn reject mr-1" color="warn" (click)=" openConfirmation(data)" [disabled]="hideReject(data.status)">Reject</button>
                                  <button class="dl-btn reject mr-1" color="warn" (click)="openConflictData(data.upload_id)" [disabled]="(data.status != 5)">Conflict</button>
                                  <button class="download-file" matTooltip="Download File" (click)="downloadFile(data)">
                                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="19" height="19" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g id="Solid"><path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z" fill="#3fc93c" data-original="#000000" class=""></path><path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z" fill="#3fc93c" data-original="#000000" class=""></path></g></g></svg>
                                  </button>
                              </td>
                          </tr>
                      </tbody>
                      <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                  </table>
          </div>
          <mat-paginator  [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
          </mat-paginator>
      </mat-card-content>
  </mat-card>
</div>
