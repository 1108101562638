import { Component, OnInit } from '@angular/core';
import { AdminBreadcrumbService } from '../admin-breadcrumb/services/admin-breadcrumb.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ApiserviceService } from '../../apiservice.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { NotificationService } from '../services/notification.service';
import { environment } from '../../../environments/environment';
import { debounceTime } from 'rxjs';


@Component({
  selector: "app-in-progress",
  templateUrl: "./in-progress.component.html",
  styleUrls: ["./in-progress.component.css"],
})
export class InProgressComponent implements OnInit {
  loader: boolean = false;
  displayedColumns = [
    "sno",
    "id",
    "module",
    "process_name",
    "user_id",
    "parameter",
    "added_date",
    "updated_date",
    "time_taken",
    "status",
    "actions",
  ];
  dataSource:any = [];

  offset = 0;
  limit = 10;

  searchField: FormControl;
  search_input: any;
  totalRecord: number = 0;
  pageIndex: number = 1;
  in_progress_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  statusList = {
    "all":"All",
    "1": "In progress",
    "2": "Success",
    "-1": "Failed",
  };
  status_list = [
    {
      "id":"all",
      "label": "All",
    },
    {
      "id":"1",
      "label": "In progress",
    },
    {
      "id":"2",
      "label": "Success",
    },
    {
      "id":"-1",
      "label": "Failed",
    },
  ];
  status = 'all';
  selectedrow_id = '';
  constructor(
    private _crumbs: AdminBreadcrumbService,
    private menu: AdminMenusService,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private notify: NotificationService
  ) {
    this.menu.setActive("in-progress");
    this.breadcrumb()
    iconRegistry.addSvgIcon(
      "edit-icon",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svgicons/edit.svg"
      )
    );

    this.getAllowResourcesApi();
  }
  // getting allow resources
  getAllowResourcesApi() {
    this.loader=true;
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe(
      (res: any) => {
        this.api.allowResources = res.data;
        this.in_progress_access = this.fnc.checkResourceAccess("in_progress");
       setTimeout(() => {
        if (this.in_progress_access.GET) {
          this.getProgressData();
        } else {
          this.loader=false;
          this.notify.notify(
            "You're not authorized get in-progress process",
            "warn"
          );
        }
       }, 10);
      },
      (err) => {
        this.loader=false;
      }
    );
  }
  selectionChange(val) {
    this.status = val.value;
    this.offset = 0;
    this.pageIndex = 1;
    this.totalRecord=0;
    this.getProgressData();
  }

  getProgressData() {
    this.loader = true;
    let url = `center-process-status?order_by=added_date&sort_asc=false&limit=${this.limit}&offset=${this.offset}`;
    if (this.status != 'all') {
      url += `&status=${this.status}`;
    }
    if (this.offset == 0) {
      url += `&is_count=true`;
    }
    if (this.search_input) {
      url += `&process_name=${this.search_input}`;
    }
    if(this.in_progress_access.POST == false){
      url += `&user_id=${this.api.user_id}`
    }
    this.breadcrumb();
    this.dataSource=[];
    this.api.getNodeData(url).subscribe(
      {next :(res: any) => {
        this.loader = false;
        if (res && res.data && res.data.length) {
          this.dataSource = res.data;
          if (this.offset == 0) {
            this.totalRecord = res.totalRecord;
            this.breadcrumb();
          }
        } else {
          this.dataSource = [];
          if (this.offset == 0) {
            this.totalRecord = res.totalRecord;
            this.breadcrumb()
          }
        }
      },
      error : (err) => {
        this.loader = false;
      }}
    );
  }

  breadcrumb(){
    this._crumbs.clear();
    this._crumbs.addcrumb = {
      title: `${this.statusList[this.status]} Process (${this.totalRecord})`,
    };
  }
  ngOnInit(): void {
    this.searchField = new FormControl();
    this.searchField.valueChanges
    .pipe(debounceTime(600))
    .subscribe((term) => {
      if (term) {
        this.search_input = term.trim();
        this.offset = 0;
        this.pageIndex = 1;
        this.getProgressData();
      } else {
        this.search_input = "";
        this.offset = 0;
        this.pageIndex = 1;
        this.getProgressData();
      }
    });
  }

  getName(name: string) {
    if (name) {
      return name.replace(/_/g, " ");
    }
    return "";
  }
  getDateFormat(date) {
    if (date) {
      return this.fnc.formatDateUTC(date, true);
    }
    return "";
  }

  //
  pageAction(e) {
    this.pageIndex = e;
    this.offset = (this.pageIndex - 1) * this.limit;
    this.getProgressData();
  }

  stop(val) {

    if(!this.in_progress_access.PATCH){
      this.notify.notify(
        "You're not authorized to stop process",
        "warn"
      );
    }
    this.loader = true;
    this.selectedrow_id = val.id;
    let body = {
      id: val.id,
      updated_by: this.api.user_id,
      status: -1,
    };
    this.api.patchNodeData(`center-process-status`, body).subscribe(
      (res: any) => {
        this.loader = false;
        this.selectedrow_id = null;
        if (res && res.status == 201) {
          // this.getProgressDataAll();
          this.getProgressData();

        }
      },
      (err) => {
        this.selectedrow_id = null;
        this.loader = false;
      }
    );
  }
  // getProgressDataAll(){
  //   this.api.getCsvData(`center-process-status?status=1&order_by=added_date&sort_asc=false`).subscribe((res:any)=>{
  //     if(res && res.data && res.data.length){
  //       this.socketService.message$.next(res.data);
  //     }else{
  //       this.socketService.message$.next(null);
  //     }
  //   })
  // }
  downloadFile(element){
    if(element.file_link){
      let apiUrl = `${environment.csvUrl}download-template?city_id=${this.api.city_id}&file_url=${element.file_link}`;
      window.open(apiUrl, "_blank");
    }else{
      this.notify.notify(
        "File not found",
        "warn"
      );
    }
  }
}
